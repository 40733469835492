<template>
  <div>
      <el-table
      :data="brand"
      style="width: 100%"
      v-loading="isLoading"
      class="ml20">
      <el-table-column
        align="center"
        label="Брэнд">
        <template slot-scope="scope">
          {{scope.row.brand_name_mon}} ({{scope.row.total}})
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button :disabled="scope.row.pendingCount === 0" type="warning" @click="onPressButton(scope.row, 'pending')">Хүлээгдэж буй - {{scope.row.pendingCount}}</el-button>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button :disabled="scope.row.cancelCount === 0" type="info" @click="onPressButton(scope.row, 'cancel')">Цуцалсан - {{scope.row.cancelCount}}</el-button>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button :disabled="scope.row.acceptedCount === 0" type="success" @click="onPressButton(scope.row, 'accepted')">Баталгаажсан - {{scope.row.acceptedCount}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import services from '../../../../helpers/services'
export default {
  name: 'SupplierTable',
  props: [
    'isLoading',
    'status',
    'brand',
    'totalCount',
    'curentPageHandler',
    'sizeChangeHandler',
    'pageSize',
    'currentPage'
  ],
  data () {
    return {
      page: 1
    }
  },
  methods: {
    onPressButton (data, status) {
      this.$router.push({
        name: 'supplierDetail',
        params: {
          data: data,
          status: status
        }
      }).catch(() => {})
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    cancelAndAccepted (status, variant) {
      const body = {
        id: variant.id,
        is_accepted: status
      }
      services.cancelAndAccept(body).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, 'success')
        } else {
          this.alertReporter('Алдаа', response.message, 'error')
        }
      })
    }
  }
}
</script>

<style>

</style>
