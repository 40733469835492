<template>
  <div>
    <el-row :gutter="0">
      <el-col :span="20" :offset="2">
        <header class="page-header">
          <el-row :gutter="20" align="middle">
            <el-col :span="12">
              <h2>Үнэ батлах жагсаалт</h2>
            </el-col>
            <el-col :span="12">
              <div class="actions text-right">
                <router-link to="/add-price">
                  <el-button type="success" class="item">Нэмэх</el-button>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </header>
        <!-- Tabled content -->
        <div class="panel tabled">
          <el-tabs v-model="activeTabStatus" @tab-click="onClickTab">
            <el-tab-pane :label="tab.label" :name="tab.value" v-for="(tab, indexTab) in tabData" :key="indexTab">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="20" align="middle" type="flex">
                    <el-col :span="12">
                      <el-select placeholder="Брэнд" multiple filterable v-model="brandId" @change="onPressSelect">
                        <el-option
                          v-for="(item, index) in brandList"
                          :key="index"
                          :label="item.brand_name_mon"
                          :value="item.brand_id">
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="12" class="mr20">
                      <div class="block">
                        <el-row :gutter="20">
                          <el-col :span="8">
                            <el-select placeholder="Хямдралтай эсэх" v-model="is_discount" @change="onPressSelect">
                              <el-option
                                v-for="(item, index) in discountData"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                          </el-col>
                          <el-col :span="8">
                            <el-date-picker
                              @change="onPressSelect"
                              v-model="start_date"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              placeholder="Эхлэх огноо">
                            </el-date-picker>
                          </el-col>
                          <el-col :span="8">
                            <el-date-picker
                              @change="onPressSelect"
                              v-model="end_date"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              placeholder="Дуусах огноо">
                            </el-date-picker>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <supplier-table :brand="brand" :status="status" :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler" :isLoading="isLoading"></supplier-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <!-- Product search -->
    <el-dialog
      title="Бүтээгдэхүүн сонгох"
      :visible.sync="visibleProductDialog"
      top="10vh"
      width="500"
    >
      <div>
        <el-input
          placeholder="Барааны нэр, SKU ..."
          v-model="searchText"
        >
          <template slot="prepend"><i class="el-icon-search"></i></template>
        </el-input>
      </div>
      <div class="mt20" style="height:300px">
        <ul class="transfer-product-list" v-if="brand">
          <li v-for="(product, index) in brand.filter(data => !searchText || data.productId.toString().toLowerCase().includes(searchText.toString().toLowerCase()) || data.name_mon.toString().toLowerCase().includes(searchText.toString().toLowerCase()))" :key="index">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col :span="2">
                <div class="image-holder">
                  <img :src="product.image" alt="">
                </div>
              </el-col>
              <el-col :span="20">
                {{product.name_mon}}
              </el-col>
              <el-col :span="2" class="text-right">
                <el-checkbox v-model="product.checked" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
              </el-col>
            </el-row>
            <ul>
              <li v-for="(variant, index1) in product.variants" :key="index1">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="18" :offset="2">
                    <div>
                      {{variant.variant_name}}
                    </div>
                    <div class="text-color-secondary">
                      {{variant.variant_sku}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="text-center">
                      {{variant.availableCount}}
                    </div>
                  </el-col>
                  <el-col :span="2" class="text-right">
                    <el-checkbox v-model="variant.checked" @change="checkVariant(product, variant)"></el-checkbox>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="visibleProductDialog = false">Cancel</el-button>
          <el-button @click="checkedOrders(brand)" type="primary">Хадгалах</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import supplierTable from '../priceApproval/component/supplierTable'
import services from '../../../helpers/services'
// import { login } from '../../pages/user/login.vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { getName } from '../../../utils/auth'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'supplierList',
  components: {
    supplierTable
  },
  data () {
    return {
      is_discount: null,
      start_date: '',
      end_date: '',
      totalCount: 0,
      currentPage: 1,
      pageSize: 20,
      isLoading: false,
      search: '',
      activeTabStatus: '',
      status: '',
      userName: '',
      searchText: '',
      visibleProductDialog: false,
      brand: null,
      brandList: null,
      from: '',
      brandId: [],
      size: '',
      discountData: [{
        value: '',
        label: 'Бүгд'
      }, {
        value: true,
        label: 'Хямдралтай'
      }, {
        value: false,
        label: 'Хямдралгүй'
      }],
      tabData: [
        {
          label: 'Бүгд',
          value: ''
        }, {
          label: 'Хүлээгдэж буй',
          value: 'pending'
        }, {
          label: 'Цуцалсан',
          value: 'cancel'
        }, {
          label: 'Баталгаажсан',
          value: 'accepted'
        }
      ]
    }
  },
  async created () {
    this.userName = await getName()
  },
  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.from = from
    this.size = size
    this.getAllBrands()
    this.statusPrice(from, size, this.status)
  },
  methods: {
    generateDateTime () {
      if (this.start_date === null || this.end_date === null) {
        this.start_date = ''
        this.end_date = ''
      }
    },
    onPressSelect () {
      this.statusPrice((this.pageSize * (this.currentPage - 1)), this.pageSize, this.status)
    },
    onClickTab (data) {
      if (data) {
        this.currentPage = 1
        this.status = data.name
      }
      this.is_discount = null
      this.getAllBrands()
      this.statusPrice((this.pageSize * (this.currentPage - 1)), this.pageSize, this.status)
    },
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'supplierList', query: { page: this.currentPage, size: this.pageSize } }).catch(() => {})
      this.statusPrice((this.pageSize * (this.currentPage - 1)), this.pageSize, this.status)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'supplierList', query: { page: item, size: this.pageSize } }).catch(() => {})
      this.statusPrice((this.pageSize * (this.currentPage - 1)), this.pageSize, this.status)
    },
    statusPrice (from, size, status) {
      this.generateDateTime()
      this.isLoading = true
      const body = {
        brand_ids: this.brandId
      }
      const query = '?size=' + size + '&from=' + from + '&type=' + status + '&start_date=' + this.start_date + '&end_date=' + this.end_date + '&is_discount=' + this.is_discount
      services.statusPrice(query, body).then(response => {
        this.brand = response.data
        this.totalCount = response.count
        this.isLoading = false
      })
    },
    getAllBrands () {
      this.isLoading = true
      const query = '/getAllBrands?type=' + this.status
      services.getApprovalPrice(query).then(response => {
        this.brandList = response.data
        this.isLoading = false
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
<style>
.el-input-group__prepend {
  background-color: #ffffff;
}
.el-table::before {
  content: unset;
}
.el-table tr:last-child td {
  border-bottom: none;
}
</style>
